import React, {Fragment} from 'react';
import WebFont from 'webfontloader';
import '../../App.css'


const SignatureCC = ({prenom, nom, poste, mobile, phraseAccroche, linkAccroche, file}) => {
    WebFont.load({
        custom: {
            families: ['Triade-Upright', 'sans-serif'],
            urls: ['../app.css']
        }
    });
    return (
      <Fragment>
        <html
          xmlns="http://www.w3.org/1999/xhtml"
          id="CC"
          style={{ fontFamily: "Montserrat" }}
        >
          <head>
            <title>Signature Canard & Cie</title>
            <style type="text/css" id="stylesign">
              @import url('https://use.typekit.net/hly2yus.css')
            </style>
            <link
              rel="stylesheet"
              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css"
              integrity="sha512-+4zCK9k+qNFUR5X+cKL9EIR+ZOhtIloNl9GIKS57V1MyNsYpYcUrUeQc9vNfzsWfV28IaLL3i96P9sdNyeRssA=="
              crossOrigin="anonymous"
            />
          </head>
          <body>
            <table>
              <tbody>
                <tr>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      fontStyle: "italic",
                      fontWeight: "bold",
                      fontSize: "8pt",
                      paddingBottom: "10px",
                      color: "#033859",
                    }}
                  >
                    <span role="img" aria-label="stars">
                      ✨
                    </span>
                    Breaking News
                    <span role="img" aria-label="stars">
                      ✨
                    </span>{" "}
                    Venez découvrir nos nouveaux bureaux au 16 rue Bleue 75009
                    Paris &nbsp;
                    <p style={{
                        fontWeight: "initial",
                    }}>
                      NB : Le siège social de Groupe A&C se situe au 115 rue
                      d'Aboukir 75002 Paris
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <table style={{ width: "470px" }}>
              <tbody>
                <tr>
                  <td style={{ width: "120px" }}>
                    <img
                      className="logo-sign"
                      style={{
                        borderRadius: "100%",
                        background: "#eee no-repeat center",
                        backgroundSize: "cover",
                        width: "110px",
                        height: "110px",
                        marginRight: "15px",
                      }}
                      src={file}
                      alt="logo-sign"
                    />
                  </td>
                  <td
                    style={{
                      borderLeft: "2px solid #033859",
                      width: "5px",
                      height: "100px",
                      paddingRight: "30px",
                      marginLeft: "10px",
                    }}
                  ></td>
                  <td style={{ width: "230px" }}>
                    <table style={{ color: "#033859", width: "160px" }}>
                      <tr>
                        <td
                          style={{
                            fontFamily: "montserrat, sans-serif",
                            fontWeight: "900",
                            fontSize: "11pt",
                            height: "11px",
                            textTransform: "capitalize",
                          }}
                        >
                          {prenom} {nom}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontFamily: "montserrat, sans-serif;",
                            height: "10px",
                            fontWeight: "700",
                            fontSize: "10pt",
                          }}
                        >
                          {poste}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontFamily: "montserrat, sans-serif;",
                            paddingBottom: "6px",
                            height: "5px",
                            fontWeight: "700",
                            fontSize: "10pt",
                          }}
                        >
                          T. {mobile}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontFamily: "montserrat, sans-serif;",
                            lineHeight: "6pt",
                            paddingTop: "6px",
                            paddingBottom: "20px",
                            height: "11px",
                            fontWeight: "700",
                          }}
                        >
                          <a
                            style={{
                              color: "#F57B55",
                              fontSize: "10pt",
                              whiteSpace: "nowrap",
                            }}
                            href="https://www.canardetcie.com"
                          >
                            www.canardetcie.com
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            lineHeight: "10pt",
                            height: "11px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/signature-668ad.appspot.com/o/logo%20canard.png?alt=media&token=8cdeb284-5dec-43cc-8f1f-64a1d2b8b306"
                            width="145"
                            alt="logo-canard"
                          />
                        </td>
                        <td
                          style={{
                            lineHeight: "10pt",
                            height: "11px",
                            whiteSpace: "nowrap",
                            paddingLeft: 11,
                          }}
                        >
                          <div
                            style={{
                              color: "#033859",
                              backgroundColor: "#033859",
                              height: 25,
                              width: 1,
                            }}
                          ></div>
                        </td>
                        <td>
                          <a
                            style={{
                              fontFamily: "montserrat, sans-serif;",
                              color: "#033859",
                              margin: 10,
                            }}
                            href="https://www.linkedin.com/company/canard-&-cie/"
                          >
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/signature-668ad.appspot.com/o/linkedin.png?alt=media&token=263f5252-6128-4266-a9ff-2656e15014f7"
                              alt={"logo-linkedin"}
                              width={"20"}
                            />
                          </a>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{ marginTop: "20px", height: "55px" }}
            >
              <tr>
                <td
                  style={{
                    paddingBottom: "3px",
                  }}
                >
                  <a href={linkAccroche} style={{ textDecoration: "none" }}>
                    <p
                      style={{
                        color: "#5BC2CD",
                        fontWeight: "bold",
                        fontSize: "11pt",
                      }}
                    >
                      {phraseAccroche}
                    </p>
                  </a>
                </td>
              </tr>
            </table>
          </body>
        </html>
      </Fragment>
    );
};

export default SignatureCC;
