import React, { Fragment, Component } from 'react';
import FileUploader from 'react-firebase-file-uploader';
import firebase from "firebase";
class SignatureForm extends Component {


render(){
  return (
      <Fragment>
        <form className="">
          <h3>Choix de la signature</h3>
          <div className="radiobuttons">

            <input
              type="radio"
              id="agence"
              name="choice"
              value="AA"
              checked={this.props.template === 'AA'}
              onChange={this.props.handleOptionChange}
            />

            <label
              htmlFor="agence"
              className="ml-2">Agence Alignée</label>

            <br></br>

            <input
              type="radio"
              id="canard"
              name="choice"
              value="CC"
              checked={this.props.template === 'CC'}
              onChange={this.props.handleOptionChange}
            />

            <label
                htmlFor="canard"
                className="ml-2">Canard & Cie</label>

          </div>
          <h3>Infos personnelles</h3>
          <div className="fields">
            <div className="d-flex flex-column">

              <label htmlFor="photo">
                Photo
              </label>

              <FileUploader
                maxWidth={220}
                maxHeight={220}
                accept="image/*"
                name='image'
                storageRef={firebase.storage().ref('avatar')}
                onUploadStart={this.props.handleUploadStart}
                onUploadSuccess={this.props.handleUploadSuccess}
                />

              <label htmlFor="firstname">Prénom</label>

              <input
                  onChange={(e) => this.props.handleChange(e, 'prenom')}
                  value={this.props.prenom}
                  className="firstname"
                  type="text"
                  name="firstname"/>

              <label htmlFor="lastname">NOM</label>

              <input
                  onChange={(e) => this.props.handleChange(e, 'nom')}
                  value={this.props.nom}
                  className="lastname"
                  type="text"
                  name="lastname"/>

              <label htmlFor="poste">Poste</label>
              <input
                  onChange={(e) => this.props.handleChange(e, 'poste')}
                  value={this.props.poste}
                  className="poste"
                  type="text"
                  name="poste"/>

              <label htmlFor="mobile">Téléphone</label>
              <input
                  onChange={(e) => this.props.handleChange(e, 'mobile')}
                  value={this.props.mobile}
                  className="mobile"
                  type="text"
                  name="mobile"/>

              <label htmlFor="phrase-accroche">Phrase d'accroche</label>
              <input
                  onChange={(e) => this.props.handleChange(e, 'phraseAccroche')}
                  value={this.props.phraseAccroche}
                  className="phrase-accroche"
                  type="text"
                  name="phrase-accroche"/>

              <label htmlFor="link-accroche">Lien phrase d'accroche</label>
              <input
                  onChange={(e) => this.props.handleChange(e, 'linkAccroche')}
                  value={this.props.linkAccroche}
                  className="link-accroche"
                  type="text"
                  name="link-accroche"/>

            </div>
          </div>
        </form>
      </Fragment>
    );
  }
};

export default SignatureForm;
