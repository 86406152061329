import 'bootstrap/dist/css/bootstrap.min.css'
import firebase from "firebase";
import 'firebase/storage'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//Config firebase
const firebaseConfig = {

  apiKey: "AIzaSyBGXWPF1YhhzFtLcfmEkWpumCWdCtkEyzY",
  authDomain: "signature-668ad.firebaseapp.com",
  databaseURL: "https://signature-668ad.firebaseio.com",
  projectId: "signature-668ad",
  storageBucket: "signature-668ad.appspot.com",
  messagingSenderId: "878959431420",
  appId: "1:878959431420:web:8e5f56723f2bed2b38d179"

}

firebase.initializeApp(firebaseConfig)

const storage = firebase.storage()



ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export {
  storage, firebase as default
}
