import React, {Component} from 'react';
import firebase from "firebase";

// CSS
import './App.css';

// Formulaire
import SignatureForm from "./components/SignatureForm";

// Templates
import SignatureAA from "./components/signatures/SignatureAA";
import SignatureCC from "./components/signatures/signatureCC";


class App extends Component {
  state = {
    file: null,

    // Templates
    template: "CC",
    prenom: "Prénom",
    nom: "Nom",
    poste: "poste",
    mobile: "06.12.34.56.78",
    phraseAccroche:
      "Canard & Cie a rejoint la communauté B Corp - L'avenir c’est par ici 👈 Vous venez ?",
    linkAccroche:
      "https://www.bcorporation.net/en-us/find-a-b-corp/company/canard-cie/",

    // CopyToClipboard component
    value: "",
    copied: false,
    image: "",
    imageURL: "",
    progress: 0,
  };

  // static images on start
  linkPlaceholderAA =
    "https://static-general.s3.eu-west-3.amazonaws.com/canardetcie/AA.png";
  linkPlaceholderCC =
    "https://firebasestorage.googleapis.com/v0/b/signature-668ad.appspot.com/o/Picture.png?alt=media&token=3104271f-4525-4f89-bc3f-296b3d099ea4";

  // catch sentences/link
  phraseAccrocheAA =
    "Découvre notre agence soeur, spécialisée en production de contenus pour les marques";
  linkAccrocheAA = "https://www.canardetcie.com";

  phraseAccrocheCC = "Vous avez aimé travailler avec nous ?";
  linkAccrocheCC = "https://reco.tf/canard-et-cie";

  handleUploadStart = () => {
    this.setState({
      progress: 0,
    });
  };

  handleUploadSuccess = (filename) => {
    this.setState({
      image: filename,
      progress: 100,
    });
    firebase
      .storage()
      .ref("avatar")
      .child(filename)
      .getDownloadURL()
      .then((url) =>
        this.setState({
          file: url,
        })
      );
  };
  copyTable = (template) => {
    const elTable = document.querySelector(`#${template}`);
    let range, sel;

    // Ensure that range and selection are supported by the browsers
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      // unselect any element in the page
      sel.removeAllRanges();

      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }
      document.execCommand("copy");
    }
    sel.removeAllRanges();

    console.log("Element Copied! Paste it in a file");
  };

  handleChange = (event, propName) => {
    let text = event.target.value;
    this.setState({ [propName]: text });
  };

  changePhraseAccroche = (value) => {
    let phraseAccroche =
      value === "AA" ? this.phraseAccrocheAA : this.phraseAccrocheCC;
    let linkAccroche =
      value === "AA" ? this.linkAccrocheAA : this.linkAccrocheCC;

    this.setState({
      phraseAccroche,
      linkAccroche,
    });
  };

  handleOptionChange = (event) => {
    let value = event.target.value;

    this.setState({ template: value });
    this.changePhraseAccroche(value);
  };

  render() {
    let {
      template,
      prenom,
      nom,
      poste,
      mobile,
      phraseAccroche,
      linkAccroche,
      file,
    } = this.state;

    return (
      <div id="d-flex container-global left" className={"container"}>
        <div className="row">
          <div className="col-md-6 col-12 signature__form">
            <SignatureForm
              file={file}
              prenom={prenom}
              nom={nom.toUpperCase()}
              poste={poste}
              mobile={mobile}
              phraseAccroche={phraseAccroche}
              template={template}
              linkAccroche={linkAccroche}
              handleChange={this.handleChange}
              handleOptionChange={this.handleOptionChange}
              handleUploadStart={this.handleUploadStart}
              handleUploadSuccess={this.handleUploadSuccess}
            />
          </div>
          <div className="signature__output col-md-6 col-12 ">
            {this.state.template === "AA" ? (
              <SignatureAA
                file={file ? file : this.linkPlaceholderAA}
                prenom={prenom}
                nom={nom.toUpperCase()}
                poste={poste}
                mobile={mobile}
                phraseAccroche={phraseAccroche}
                linkAccroche={linkAccroche}
              />
            ) : (
              <SignatureCC
                file={file ? file : this.linkPlaceholderCC}
                prenom={prenom}
                nom={nom.toUpperCase()}
                poste={poste}
                mobile={mobile}
                phraseAccroche={phraseAccroche}
                linkAccroche={linkAccroche}
              />
            )}

            <button onClick={() => this.copyTable(this.state.template)}>
              Copier dans le presse-papier
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default App;

