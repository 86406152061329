import React, {Fragment} from 'react';


const SignatureAA = ({prenom, nom, poste, mobile, phraseAccroche, linkAccroche, file='https://static-general.s3.eu-west-3.amazonaws.com/canardetcie/AA.png'}) => {

    return (
        <Fragment>
            <html xmlns="http://www.w3.org/1999/xhtml" id={'AA'}>
                <head>
                    <title>Signature Agence Alignée</title>
                    <style type="text/css" id="stylesign">
                        @import url('https://use.typekit.net/hly2yus.css')
                    </style>
                    <link
                        rel="stylesheet"
                        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css"
                        integrity="sha512-+4zCK9k+qNFUR5X+cKL9EIR+ZOhtIloNl9GIKS57V1MyNsYpYcUrUeQc9vNfzsWfV28IaLL3i96P9sdNyeRssA=="
                        crossOrigin="anonymous" />
                </head>
                <body>
                    <table style={{width:"470px"}}>
                        <tbody>
                            <tr>
                                <td style={{width:"120px"}}>
                                    <img    className="logo-sign"
                                            style={{borderRadius: "100%", background: '#eee no-repeat center' , backgroundSize:'cover' , width:"110px", height:"110px", marginRight: '15px'}}
                                            src={file} alt="" />
                                </td>
                                <td  style={{borderLeft: "2px solid #033859",width:"5px",height:"100px", paddingRight: "30px", marginLeft:'10px'}}>

                                </td>
                                <td style={{width:"230px"}}>
                                    <table  style={{color: "#033859", width:"230px"}}>
                                        <tr>
                                            <td style={{fontFamily : 'montserrat, sans-serif;', fontWeight: "900", fontSize: "11pt", height:"11px", textTransform: "capitalize"}}>
                                                {prenom} {nom}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{fontFamily : 'montserrat, sans-serif;',height:"10px", fontWeight: "800", fontSize: "10pt"}}>
                                                {poste}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{fontFamily : 'montserrat, sans-serif;', paddingBottom: "6px", height:"5px", fontWeight: "500", fontSize : '10pt'}}>
                                                T. {mobile}
                                            </td>
                                        </tr>
                                         <tr>
                                            <td style={{fontFamily : 'montserrat, sans-serif;',lineHeight: "6pt", paddingBottom: "20px", height:"11px",fontWeight: "500"}}>
                                                <a  style={{color: "#ecc6c2", fontSize : '10pt', whiteSpace: 'nowrap'}}
                                                    href="https://www.agence-alignee.com">
                                                    www.agence-alignee.com
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{lineHeight: "10pt", height:"11px", whiteSpace: 'nowrap'}}>
                                                <img
                                                    src="https://firebasestorage.googleapis.com/v0/b/signature-668ad.appspot.com/o/logo-agence-alignee-130.png?alt=media&token=7eec3bf6-ea1d-4968-8d1b-759cda3df179"
                                                    width="110"
                                                    alt="logo-agence" />
                                            </td>
                                            <td>
                                                <a style={{fontFamily : 'montserrat, sans-serif;',color: "#033859"}} href="https://www.linkedin.com/company/agence-alignée/">
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/signature-668ad.appspot.com/o/linkedin.png?alt=media&token=263f5252-6128-4266-a9ff-2656e15014f7" alt={"logo-linkedin"}/>
                                                </a>
                                                <br />
                                                <a style={{fontFamily : 'montserrat, sans-serif;',color: "#033859" }} href="https://twitter.com/_agence_alignee">
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/signature-668ad.appspot.com/o/twitter-square.png?alt=media&token=2ffe8b2a-d31a-471e-a420-ddb11bec2e5b" style={{width: "20px", height: "22px"}} alt={'logo-twitter'}/>
                                                </a>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table
                        style={{marginTop: "20px",marginBottom: '10px',width:"440px"}}>

                        <tr>
                            <td style={{width: "90px"}}>
                                <img
                                    src="https://firebasestorage.googleapis.com/v0/b/signature-668ad.appspot.com/o/logo-canard-80.png?alt=media&token=ee50ebdc-2fa4-4af6-bb94-4113b5733059"
                                    width="70"
                                    alt="logo-canard" />
                            </td>
                            <td style={{paddingBottom:"3px", height:"1px", lineHeight:"12pt", width:"400px"}}>

                                <a style={{color: "#89c5d0", fontWeight: "500", fontSize: "10pt"}}
                                    href={linkAccroche}>
                                    <u>{phraseAccroche}</u>
                                </a>
                            </td>
                        </tr>
                    </table>
                </body>
            </html>
        </Fragment>
    );
};

export default SignatureAA;